<template>
  <div class="waylay">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          生产申请
        </div>
      </div>

      <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="small">
        <el-form-item label="生产类型：" prop="productType">
          <el-select v-model="form.productType" placeholder="全部" clearable>
            <el-option v-for="item in productTypeList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="生产状态：" prop="productStatus">
          <el-select v-model="form.productStatus" placeholder="全部" clearable>
            <el-option v-for="item in productStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="创建时间：">
          <el-date-picker
            v-model="form.dateQuery"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
        </el-form-item>
        <el-form-item class="btn-right">
          <el-button @click="showDialogInit()" size="mini" type="danger">添加排班信息</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="small">
          <el-table-column type="index" label="编号" width="60"></el-table-column>
          <el-table-column prop="productNo" label="生产编码" width="160"></el-table-column>
          <el-table-column prop="productType" label="生产类型">
            <template slot-scope="scope">
              <span>{{ scope.row.productType | productTypeFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="product" label="产品名称"></el-table-column>
          <el-table-column prop="weight" label="重量:KG"></el-table-column>
          <el-table-column prop="score" label="权重"></el-table-column>
          <el-table-column label="是否置顶">
            <template slot-scope="scope">
              <span>{{ scope.row.top | topStatusFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="need" label="需要楼层">
            <template slot-scope="scope">
              <span>{{ scope.row.need | needStatusFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="mark" label="备注"></el-table-column>
          <el-table-column prop="productStatus" label="生产状态">
            <template slot-scope="scope">
              <span>{{ scope.row.productStatus | productStatusFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间">
            <template slot-scope="scope">
              <span>{{ scope.row.createdTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="280" fixed="right">
            <template slot-scope="{row}">
              <el-button @click="del(row)" type="danger" size="mini" v-if="'11'===row.productStatus">删除</el-button>
              <el-button @click="edit(row)" type="primary" size="mini" v-if="'11'===row.productStatus">编辑</el-button>
              <el-button @click="apply(row)" type="warning" size="mini" v-if="'11'===row.productStatus">申请</el-button>
              <el-button @click="tackList(row)" type="success" size="mini">记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50,100,200]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

      <!--  添加弹窗-->
      <div v-if="dialogVisibleInit">
        <el-dialog
          title="添加排班信息"
          :visible.sync="dialogVisibleInit"
          width="60%"
          :before-close="handleCancelInit">
          <!--将添加与编辑功能单独封装到组件中-->
          <create  @cancel="handleCancelInit" @success="handleSuccessInit"></create>
        </el-dialog>
      </div>

      <!--  编辑弹窗-->
      <div v-if="dialogVisibleEdit">
        <el-dialog
          title="编辑排班信息"
          :visible.sync="dialogVisibleEdit"
          width="60%"
          :before-close="handleCancelEdit">
          <!--将添加与编辑功能单独封装到组件中-->
          <edit :product-info="productInfo"  @cancel="handleCancelEdit" @success="handleSuccessEdit"></edit>
        </el-dialog>
      </div>


      <!--  跟进记录弹窗-->
      <div v-if="dialogVisibleTackList">
        <el-dialog
          title="跟进记录"
          :visible.sync="dialogVisibleTackList"
          width="60%"
          :before-close="handleCancelTackList">
          <!--将添加与编辑功能单独封装到组件中-->
          <tack-list  :product-no="productNo" @cancel="handleCancelTackList" ></tack-list>
        </el-dialog>
      </div>

    </el-card>

  </div>
</template>

<script>
import {getUserInfo} from '@/services/user'
import {dateFormate} from '@/utils/dateUtil'
import {findAllProductStatus,findAllProductType} from '@/services/status'
import {findAllProductInfoByPage,apply,del} from '@/services/produce'
import Create from '../components/Create'
import Edit from "../components/Edit";
import {productStatusToCn,productTypeToCn,needStatusToCn,topStatusToCn} from '@/utils/enums'
import TackList from "../components/TackList";
export default {
  name: 'schedule',
  components: {TackList, Create,Edit },
  data() {
    return {
      userInfo: {},
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      productStatusList: [],
      productTypeList: [],
      productInfo: {},
      dialogVisibleInit: false,
      dialogVisibleEdit: false,
      dialogVisibleTackList: false,
      productNo: null,
    }
  },
  methods: {

    // 监听子组件的添加状态，成功时触发
    handleSuccessInit () {
      // 隐藏对话框
      this.dialogVisibleInit = false
      // 刷新列表
      this.loadAllProductInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelInit () {
      // 隐藏对话框
      this.dialogVisibleInit = false
    },

    // 监听子组件的添加状态，成功时触发
    handleSuccessEdit () {
      // 隐藏对话框
      this.dialogVisibleEdit = false
      // 刷新列表
      this.loadAllProductInfoByPage()
    },
    // 监听子组件的取消状态
    handleCancelEdit () {
      // 隐藏对话框
      this.dialogVisibleEdit = false
      this.productInfo = {}
    },

    // 监听子组件的取消状态
    handleCancelTackList() {
      // 隐藏对话框
      this.dialogVisibleTackList = false
      this.productNo = null
    },

    //
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllProductInfoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllProductInfoByPage()
    },

    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      await this.loadAllProductInfoByPage()
    },

    async loadProductStatus() {
      const {data} = await findAllProductStatus()
      if (200 === data.code) {
        this.productStatusList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },

    async loadProductType() {
      const {data} = await findAllProductType()
      if (200 === data.code) {
        this.productTypeList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },


    async loadAllProductInfoByPage() {
      // 开始加载数据
      this.isLoading = true
      if (this.form && this.form.dateQuery) {
        let dateBegin = this.form.dateQuery[0]
        let dateEnd = this.form.dateQuery[1]
        this.form.dateBegin = dateBegin
        this.form.dateEnd = dateEnd
      }else {
        this.form.dateBegin = ""
        this.form.dateEnd = ""
      }
      if (!this.userInfo||!this.userInfo.userName) {
        await this.loadUserInfo()
      }
      this.form.tackName=this.userInfo.userName
      this.form.tackPhone=this.userInfo.phone
      const {data} = await findAllProductInfoByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },
    // 显示对话框
    showDialogInit() {
      this.dialogVisibleInit = true
    },

    // 显示编辑对话框
    edit(row) {
      this.productInfo = row
      this.dialogVisibleEdit = true
    },

    // 申请生产
    async apply(row) {
      const params = {
        productNo: row.productNo,
        tackName: this.userInfo.userName,
        tackPhone: this.userInfo.phone
      }
      const {data} = await apply(params)
      if (200 === data.code) {
        this.$message.success("操作成功")
      } else {
        this.$message.warning("操作失败")
      }
      await this.loadAllProductInfoByPage()
    },

    // 删除
    async del(row) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const params = {
          productNo: row.productNo,
          tackName: this.userInfo.userName,
          tackPhone: this.userInfo.phone
        }
        const {data} = await del(params)
        if (200 === data.code) {
          this.$message.success("操作成功")
        } else {
          this.$message.warning("操作失败")
        }
        await this.loadAllProductInfoByPage()
      } catch (err) {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      }
    },


     // 显示跟进记录对话框
    tackList(row) {
      this.productNo = row.productNo
      this.dialogVisibleTackList = true
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },

  },
  created() {
    this.loadUserInfo()
    this.loadProductStatus()
    this.loadProductType()
    this.loadAllProductInfoByPage()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },
    productStatusFormate(code) {
      return productStatusToCn(code)
    },
    productTypeFormate(code) {
      return productTypeToCn(code)
    },

    needStatusFormate(code) {
      return needStatusToCn(code)
    },

    topStatusFormate(code) {
      return topStatusToCn(code)
    },


  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

.btn-right {
  float: right;
}

.el-table {
  margin-top: 10px;
}

.btn-div {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
